import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {ReactComponent as ReadMoreIcon} from '../assets/img/read-more-arrow.svg';

function CustomerSectionBox(props) {
  return (
    <div
      style={{
        position: "relative",
        zIndex: "1",
      }}
      className="reuseSectionBox"
    >
      <a href={props.redirectURL} target="_blank" rel="noreferrer">
      <img
        loading="lazy"
        style={{
          // filter: "grayscale(1)",
          objectFit: "contain",
        }}
        src={props.picture}
        alt="blogs"
      /> 
      </a>
      
      {props.date && (
        <div
          id={props.color}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            fontSize: "12px",
            padding: "10px 5px",
          }}
          className="reuseBoxHover"
        >
          <div>{props.date} </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gridGap: "5px",
            }}
          >
            <AccessTimeIcon
              style={{
                fontSize: "14px",
              }}
            />{" "}
            <span>{props.read}</span>{" "}
          </div>
        </div>
      )}
      <div
        style={{
          padding: "10px 4px 4px 10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          position: "relative",
        }}
        className={props.class}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <span>
            {props.title1} {props.title2}
          </span>

          {props?.content && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: "0.2px",
                lineHeight: "18px",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              {props.content}
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <a
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="read-more-icon"
            href={`${props.redirectURL}`}  target="_blank" rel="noopener noreferrer"
          >
            <ReadMoreIcon width="50px" height="50px" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CustomerSectionBox;
